<template>
  <form action="" @submit.stop.prevent="() => {}">
    <div class="form-row desktop_form" v-if="type === 'tabs'">
      <div class="form-group col-md-3">
        <!-- <select-theme0
          @changeDestination="destChanged"
          @optionSelect="destSelect"
          :list="destinationList"
        /> -->
        <select-theme0 v-if="!isMultipleSearchCase"
          @changeDestination="destChanged"
          @optionSelect="destSelect"
          :list="destinationList"
        />
        <select-destinations v-else
          @changeDestination="changeDestinations"
          @optionSelect="destSelect"
          :type="type"
          :data="dataDestination"
        />
      </div>

      <div class="form-group col-md-3 multi-hotels">
        <hotel-theme0
          :destination="destinationCityName"
          :hotelListShow="destinationChanged"
          @changeHotel="hotelChanged"
          @optionSelect="hotelSelect"
          :list="hotelList"
        />
      </div>

      <div class="form-group col-md-3">
        <datepicker-range0
          :calendarShow="destinationChanged && hotelListChanged"
          :destination="destination"
          @finalDateSelect="finalDateSelect"
          @dates="chooseDate"
        ></datepicker-range0>
      </div>
      <div class="form-group col-md-2">
        <occupancy-pax @changeBodyCount="changeCount" :dropdownShow="dropdownShow"/>
      </div>
      <div class="form-group col-md-1 d-flex">
        <button class="btn flex-fill" @click="submitSearch" :disabled="searchDisable" style="padding:4px;">
          {{ $t("search-tab.search") }}
          <b-spinner small v-if="isLoading"></b-spinner>
          <i class="fas fa-search" v-else></i>
        </button>
      </div>
    </div>

    <Breadcrumb :data="linkDataSearch" v-if="linkDataSearch && breadcrumb==='breadcrumb'" :destination="getBreadcrumb" :page="`search`"/>

    <!-- <MobileSearchForm/> -->
    <div class="card card-body boxSideSearch border-0" :class="{ mobile_form: type === 'tabs' }">
      <div class="form-row">
        <div class="form-group col-12">
          <span>{{
            $t("search-tab.mobile-form.destination-label")
          }}</span>
          <!-- <select-theme0
            @changeDestination="destChanged"
            @optionSelect="destSelect"
            :list="destinationList"
          /> -->
          <select-theme0 v-if="!isMultipleSearchCase"
            @changeDestination="destChanged"
            @optionSelect="destSelect"
            :list="destinationList"
          />
          <select-destinations v-else
            @changeDestination="changeDestinations"
            @optionSelect="destSelect"
            :type="type"
            :data="dataDestination"
          />
        </div>

        <div class="form-group col-12 multi-hotels">
          <span>{{
            $t("search-tab.mobile-form.hotel-label")
          }}</span>
          <hotel-theme0
            :destination="destinationCityName"
            :hotelListShow="destinationChanged"
            @changeHotel="hotelChanged"
            @optionSelect="hotelSelect"
            :list="hotelList"
            :multiple="true"
          />
        </div>

        <div class="form-group col-12">
          <span>{{ $t("search-tab.mobile-form.start-date") }}</span>
          <div class="input-group">
            <datepicker-range0
              :calendarShow="destinationChanged && hotelListChanged"
              :destination="destination"
              @dates="chooseDate"
              type="side"
            ></datepicker-range0>
          </div>
        </div>
        <div class="form-group col-4">
          <b-form-select class="form-control" v-model="countAdult">
            <template #first>
              <b-form-select-option :value="0" disabled>{{
                $t("search-tab.adult")
              }}</b-form-select-option>
            </template>
            <option v-for="inx in 6" :key="inx" :value="inx - 1">{{ inx - 1 }}</option>
          </b-form-select>
        </div>
        <div class="form-group col-4">
          <b-form-select class="form-control" v-model="countChild">
            <template #first>
              <b-form-select-option :value="0" disabled>{{
                $t("search-tab.child")
              }}</b-form-select-option>
            </template>
            <option v-for="inx in 6" :key="inx" :value="inx - 1">{{ inx - 1 }}</option>
          </b-form-select>
        </div>
        <div class="form-group col-4">
          <b-form-select class="form-control" v-model="countInfant">
            <template #first>
              <b-form-select-option :value="0" disabled>{{
                $t("search-tab.infant")
              }}</b-form-select-option>
            </template>
            <option v-for="inx in 6" :key="inx" :value="inx - 1">{{ inx - 1 }}</option>
          </b-form-select>
        </div>
        <div class="form-group col-6 d-flex">
          <button
            class="btn flex-fill btn-outline-dark btn-search"
            @click="submitSearch"
            :disabled="searchDisable"
          >
            {{ $t("search-tab.search") }}
            <b-spinner v-if="isLoading" small></b-spinner>
            <i class="fas fa-search" v-else></i>
          </button>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import {
  BFormSelect,
  BFormSelectOption,
  BSpinner,
} from 'bootstrap-vue';
import TemplateSearchTab from './TemplateSearchTab';

export default {
  name: 'SearchTabTheme0',
  mixins: [TemplateSearchTab],
  components: {
    OccupancyPax: () => import('@/components/atoms/occupancyPax/OccupancyPaxTheme0'),
    SelectTheme0: () => import('@/components/atoms/searchSelect/SearchSelectTheme0'),
    SelectDestinations: () => import('@/components/atoms/SelectDestinations'),
    HotelTheme0: () => import('@/components/atoms/multipleSelect/MultipleSelectTheme0'),
    DatepickerRange0: () => import('@/components/atoms/customDatepickerRange/CustomDatepickerRangeTheme0'),
    Breadcrumb: () => import('@/components/productPage/Breadcrumb'),
    BFormSelect,
    BFormSelectOption,
    BSpinner,
  },
};
</script>

<style scoped>
@media (max-width: 479px) {
  .mobile_form {
    display: block;
  }
  .desktop_form {
    display: none;
  }
}
.custom-control {
  padding-right: 1rem;
}
</style>

<style>
.custom-control-input {
  left: auto;
  right: 0;
}
.filter-tab .custom-control-label::before,
.filter-tab .custom-control-label::after {
  right: -1.5rem;
  left: auto;
}
.vs__dropdown-toggle {
  background: #e9ecef !important;
}
</style>
